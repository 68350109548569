import {Outlet} from "react-router-dom";
import {Box, Container} from "@mui/material";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const PageLayout = () => {
    return (
        <Box display="flex" flexDirection="column" minHeight="100vh">
            <Navbar />
            <Box component="main" sx={{ mt: 4, flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                <Container sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', pt: 4, pb: 2, px: 2 }}>
                    <Outlet />
                </Container>
            </Box>
            <Footer />
            <div className="background">
                <div className="overlay"></div>
                <div className="stripes"></div>
            </div>
        </Box>
    );
}
export default PageLayout;