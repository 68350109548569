import Routes from "./router/index"
import './assets/styles/App.css';

function App() {
  return (
      <Routes/>
  );
}

export default App;
