import React from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import darkTheme from './darkTheme';

const AppThemeProvider = ({ children }) => {
    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    );
};

export default AppThemeProvider;
