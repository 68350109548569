import React from 'react';
import {Box, Typography, Link, Container, Grid, Divider} from '@mui/material';
import {NavLink} from 'react-router-dom';
import Logo from '../assets/images/logo.png';

const Footer = () => {
    return (
        <Box component="footer" sx={{py: 3, px: 2, mt: 'auto', background: 'rgba(26,26,26,0.6)', backdropFilter: 'blur(1px)' }}>
            <Container maxWidth="lg">
                <Grid container alignItems="center">
                    {/* Copyright and Links Section */}
                    <Grid item xs={12} sm={12} md={4}>
                        <Grid container>
                            {/* Logo Section */}
                            <Grid item xs={6} sm={6} display="flex" justifyContent="center">
                                <img src={Logo} alt="Rustlin Logo" style={{maxWidth: '150px'}}/>
                            </Grid>
                            <Grid item xs={6} sm={6} display="flex" flexDirection="column" justifyContent="center"
                                  alignItems="flex-start">
                                <Box>
                                    <Typography variant="body1">
                                        © 2024 Rustlin
                                    </Typography>
                                    <Typography variant="button" display="block">
                                        ALL RIGHTS RESERVED.
                                    </Typography>
                                    <Typography variant="caption" display="block">
                                        Created in June 2024
                                    </Typography>
                                </Box>
                                <Box mt={2} display="flex">
                                    <Typography variant="caption" sx={{mr: 2}}>
                                        <Link component={NavLink} to="/imprint" color="inherit" underline="none">
                                            Imprint
                                        </Link>
                                    </Typography>
                                    <Typography variant="caption">
                                        <Link component={NavLink} to="/privacy" color="inherit" underline="none">
                                            Privacy Policy
                                        </Link>
                                    </Typography>
                                </Box>
                                <Box mt={2} display="flex">
                                    <Typography variant="caption" sx={{mr: 2}}>
                                        <Link component={NavLink} to="/tos" color="inherit" underline="none">
                                            TOS
                                        </Link>
                                    </Typography>
                                    <Typography variant="caption">
                                        <Link component={NavLink} to="/cookie" color="inherit" underline="none">
                                            Cookie Policy
                                        </Link>
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Divider orientation="vertical" flexItem sx={{
                        m: 2,
                        display: {xs: 'none', sm: 'none', md: 'block'},
                        borderColor: 'rgba(255, 255, 255, 0.12)'
                    }}/>
                    <Grid item xs={12} sx={{display: {xs: 'block', sm: 'block', md: 'none'}}}>
                        <Divider sx={{m: 2, borderColor: 'rgba(255, 255, 255, 0.12)'}}/>
                    </Grid>

                    {/* About Us Section */}
                    <Grid item xs={12} sm={12} md={7} display="flex" alignItems="center">
                        <Box>
                            <Box sx={{textDecoration: 'underline'}}>
                                <Typography variant="h6" color="textPrimary" gutterBottom>
                                    About us
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="caption">
                                    We're a new team aiming to be one of the best Rust servers. With extensive hosting
                                    experience and successful past projects, we're dedicated to delivering top-tier
                                    gameplay. Join us at Rustlin and be part of our journey!
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default Footer;
