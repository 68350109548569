import { createTheme } from '@mui/material';
import { grey, blue } from '@mui/material/colors';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: blue[700],
        },
        secondary: {
            main: grey[700],
        },
        background: {
            default: '#444654',
            paper: '#202124',
        },
        text: {
            primary: grey[200],
            secondary: grey[400],
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    '&::-webkit-scrollbar': {
                        width: '8px',
                        height: '8px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: 'rgba(255, 255, 255, 0.3)',
                        borderRadius: '4px',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    },
                },
            },
        },
    },
});

export default darkTheme;
