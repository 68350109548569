import React, {useState} from 'react';
import {AppBar, Toolbar, Typography, Box, Link, IconButton, Drawer, List, ListItem} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {Link as RouterLink, NavLink} from 'react-router-dom';
import {useMediaQuery, useTheme} from '@mui/material';
import Logo from '../assets/images/logo.png';

const Navbar = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    const navLinks = [
        {title: 'Home', path: '/', color: '#d9d8d8', activeColor: '#e5a3a3', bolt: false, underline: false, underlineOffset: '0px', external:false},
        {title: 'Servers', path: '/servers', color: '#d9d8d8', activeColor: '#e5a3a3', bolt: false, underline: false, underlineOffset: '0px', external:false},
        {title: 'Ranking', path: '/ranking', color: '#d9d8d8', activeColor: '#e5a3a3', bolt: false, underline: false, underlineOffset: '0px', external:false},
        {title: 'Store', path: 'https://store.rustlin.gg', color: '#faa93e', activeColor: '#e5a3a3', bolt: true, underline: true, underlineOffset: '8px', external:true},
        {title: 'Staff', path: '/staff', color: '#d9d8d8', activeColor: '#e5a3a3', bolt: false, underline: false, underlineOffset: '0px', external:false},
        {title: 'Discord', path: 'https://discord.rustlin.gg', color: '#d9d8d8', activeColor: '#e5a3a3', bolt: false, underline: false, underlineOffset: '0px', external:true},
        {title: 'Steam', path: '/steam', color: '#d9d8d8', activeColor: '#e5a3a3', bolt: false, underline: false, underlineOffset: '0px', external:false},
        // {title: 'Apply', path: '/apply', color: '#d9d8d8', activeColor: '#ffffff', bolt: false, underline: false, underlineOffset: '0px'},
    ];

    const drawer = (
        <Box
            sx={{width: 250, background: 'rgba(26,26,26,0.6)', height: '100%'}}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                {navLinks.map((link) => (
                    <ListItem
                        component={NavLink}
                        to={link.path}
                        key={link.title}

                        style={({isActive}) => ({
                            backgroundColor: isActive ? theme.palette.primary.main : 'transparent',
                            color: isActive ? link.activeColor : link.color,
                            fontWeight: isActive || link.bolt ? '800' : '300',
                            textDecoration: link.underline || isActive ? 'underline' : 'none',
                            textUnderlineOffset: isActive ? '4px' : link.underlineOffset,
                            textDecorationThickness: isActive ? '4px' : '2px',
                        })}
                        sx={{
                            boxShadow: 1,
                            mb:1
                        }}
                    >
                        <Box sx={{p: 1,}}>
                            {link.title}
                        </Box>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <AppBar position="fixed" style={{background: 'rgba(26,26,26,0.6)', backdropFilter: 'blur(1px)'}}>
            <Toolbar>
                <Box display="flex" alignItems="center" sx={{flexGrow: 1}}>
                    <img src={Logo} alt="Logo" style={{marginRight: '10px', width: '40px', height: '40px'}}/>
                    <Typography variant="h6" component="div"
                                sx={{fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '1.5rem'}}>
                        <Link component={RouterLink} to="/" color="inherit" underline="none">
                            RUSTLIN.GG
                        </Link>
                    </Typography>
                </Box>

                {isMobile ? (
                    <>
                        <IconButton
                            color="inherit"
                            edge="start"
                            onClick={toggleDrawer(true)}
                            sx={{mr: 2}}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Drawer
                            anchor="right"
                            open={drawerOpen}
                            onClose={toggleDrawer(false)}
                        >
                            {drawer}
                        </Drawer>
                    </>
                ) : (
                    <Box>
                        {navLinks.map((link) => (
                            <Link
                                target={link.external ? '_blank' : '_self'}
                                component={NavLink}
                                to={link.path}
                                color="inherit"
                                underline="none"
                                sx={{mx: 2}}
                                style={({isActive}) => ({
                                    fontWeight: isActive || link.bolt ? 'bold' : 'normal',
                                    color: isActive ? link.activeColor : link.color,
                                    textDecoration: link.underline || isActive ? 'underline' : 'none',
                                    textUnderlineOffset: isActive ? '4px' : link.underlineOffset,
                                    textDecorationThickness: isActive ? '3px' : '1px',
                                })}
                                key={link.title}
                            >
                                {link.title}
                            </Link>
                        ))}
                    </Box>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;