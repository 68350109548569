import React from 'react';
import { Navigate } from 'react-router-dom';

const RouteGuard = ({ children, needsLogin }) => {
    const isAuthenticated = false;
    if (needsLogin && !isAuthenticated) {
        return <Navigate to="/login" />;
    }
    return children;
};

export default RouteGuard;
