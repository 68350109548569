import React, { lazy } from 'react';
import Loadable from './Loadable';
import RouteGuard from './RouteGuard';
import PageLayout from '../layouts/PageLayout';

const Home = Loadable(lazy(() => import('../pages/HomePage')));
const Servers = Loadable(lazy(() => import('../pages/ServersPage')));
const Staff = Loadable(lazy(() => import('../pages/StaffPage')));
const Ranking = Loadable(lazy(() => import('../pages/RankingPage')));
const Store = Loadable(lazy(() => import('../pages/StorePage')));
const Discord = Loadable(lazy(() => import('../pages/DiscordPage')));
const Steam = Loadable(lazy(() => import('../pages/SteamPage')));
const Apply = Loadable(lazy(() => import('../pages/ApplyPage')));
const Imprint = Loadable(lazy(() => import('../pages/ImprintPage')));
const Privacy = Loadable(lazy(() => import('../pages/PrivacyPage')));
const TOS = Loadable(lazy(() => import('../pages/TOSPage')));
const Cookie = Loadable(lazy(() => import('../pages/CookiePage')));

const Instagram = Loadable(lazy(() => import('../pages/InstagramPage')));
const Reddit = Loadable(lazy(() => import('../pages/RedditPage')));
const TikTok = Loadable(lazy(() => import('../pages/TikTokPage')));
const Twitch = Loadable(lazy(() => import('../pages/TwitchPage')));
const Twitter = Loadable(lazy(() => import('../pages/TwitterPage')));
const YouTube = Loadable(lazy(() => import('../pages/YouTubePage')));


const LoginRoutes = {
    path: '/',
    element: (
        <RouteGuard needsLogin={false}>
            <PageLayout />
        </RouteGuard>
    ),
    children: [
        {
            path: '/',
            element: <Home />
        },
        {
            path: '/servers',
            element: <Servers />
        },
        {
            path: '/staff',
            element: <Staff />
        },
        {
            path: '/ranking',
            element: <Ranking />
        },
        {
            path: '/store',
            element: <Store />
        },
        {
            path: '/discord',
            element: <Discord />
        },
        {
            path: '/steam',
            element: <Steam />
        },
        {
            path: '/apply',
            element: <Apply />
        },
        {
            path: '/imprint',
            element: <Imprint />
        },
        {
            path: '/privacy',
            element: <Privacy />
        },
        {
            path: '/cookie',
            element: <Cookie />
        },
        {
            path: '/tos',
            element: <TOS/>
        },
        {
            path: '/instagram',
            element: <Instagram/>
        },
        {
            path: '/reddit',
            element: <Reddit/>
        },
        {
            path: '/tikTok',
            element: <TikTok/>
        },
        {
            path: '/twitch',
            element: <Twitch/>
        },
        {
            path: '/twitter',
            element: <Twitter/>
        },
        {
            path: '/youtube',
            element: <YouTube/>
        }
    ]
};

export default LoginRoutes;
