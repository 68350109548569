import {useRoutes} from 'react-router-dom';
import PageRoutes from "./HomeRoutes";



export default function ThemeRoutes() {
    return useRoutes([
        PageRoutes,
    ]);
}
